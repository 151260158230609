.mp-log-page {
  display: flex;

  .mp-log-container {
    .ta-row {
      background-color: var(--sdds-grey-50);
      padding: var(--sdds-spacing-layout-16);
      margin-bottom: var(--sdds-spacing-layout-32);
    }

    .dt-row {
      .dt-col {
        .dt-container {
          background-color: var(--sdds-grey-50);
          border: 1px solid var(--sdds-grey-400);
          border-radius: 5px;
          overflow-x: auto;

          .btn-export {
            svg {
              width: 16px;
              height: 16px;
            }

            .sdds-btn-icon {
              display: inline-block;
            }
          }

          .spinner {
            margin-top: var(--sdds-spacing-layout-16);
            position: relative;
            z-index: 100;
          }
        }
      }
    }

    span.cd-table__searchbar-icon svg {
      color: var(--sdds-grey-958)
    }

    span.sdds-btn-icon svg {
      color: var(--sdds-btn-primary-color);
    }
  }

  @media (min-width: 1584px) {
    .sdds-container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .sdds-table .sdds-table__upper-bar-flex {
    align-items: center
  }

  .sdds-table .sdds-btn {
    margin-left: 12px;

  }

  .no-data-message {
    margin: var(--sdds-spacing-layout-16);
  }
}