.equipment-csd-page {
  display: flex;

  .overview-collapsed+.right-side-view {
    width: calc(100vw - 13px);
  }

  .right-side-view {
    width: calc(100% - 500px);
    display: flex;
    flex-direction: column;
    margin: var(--sdds-spacing-layout-24);

    /* Portrait and Landscape */
    @media only screen and (min-width: 768px) and (max-width: 1525px) {
      overflow-y: visible;
      max-height: max-content
    }

    .panel-wrapper {
      display: flex;
      margin-top: var(--sdds-spacing-layout-24);

      .grid-panel {
        display: flex;
        flex-grow: 1;

        .cd-table-wrapper {

          width: auto;
          max-width: 100%;

          .table-wrapper {
            overflow-y: auto;
            max-height: calc(100vh - 210px) !important;
            border: 1px solid var(--sdds-grey-400);

            .sdds-table {
              .sdds-table__header {
                position: sticky;
                top: 0
              }
            }
          }
        }
      }

      .csd-details-panel {
        display: flex;
        margin-left: var(--sdds-spacing-layout-16);
        flex-grow: 1;

        div {
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      /* Portrait and Landscape */
      @media only screen and (min-width: 768px) and (max-width: 1525px) {
        flex-flow: column-reverse;
        display: flex;
        overflow-y: auto;
        max-height: calc(100vh - 180px);
        .grid-panel {
          .cd-table-wrapper {
            width: 100%;

            .table-wrapper {
              overflow-y: visible;
              max-height: max-content !important;
            }
          }
        }
        .csd-details-panel{
          .sdds-inline-tabs-main{
            height: max-content;
            overflow-y: visible;
          }
        }

        .csd-details-panel {
          margin: 0;
          margin-bottom: var(--sdds-spacing-layout-24);
        }
      }
    }
  }

}