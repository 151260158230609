::-webkit-scrollbar {
  width: 20px;
  background-color: white;
  margin-right: 2px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 7.5px solid white;
  background-color: #CDD1DB;
  height: 50px;
}

.App {
  height: 100vh;
  overflow: hidden;

  /* Portrait and Landscape */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    max-height: max-content;
  }
}

body {
  max-height: 100vh;
  overflow: hidden;
  /* Portrait and Landscape */
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    overflow: visible;
    max-height: max-content;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.sdds-btn {
  &.sdds-btn-fullbleed {
    width: 100%;
  }
}

.custom-tooltip {
  display: none;
  word-wrap: break-word;
  white-space: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--sdds-white);
  background-color: var(--sdds-grey-800);
  border-radius: 1rem;
  padding: 2rem;
  max-width: 48rem;
  z-index: 20000;
  opacity: 0;
  transition: opacity 200ms ease-in 0s, visibility 200ms ease-in 0s;
}

#page-ch,#page-sa{
  background-color: var(--tds-blue-500);
  height:20px;
  display: flex;
  align-items: center;
}

.jr-page-title{
 padding-left:var(--tds-spacing-element-4);
 color:var(--tds-white);
}

.jr-inner-section-play-icon svg {
  width: var(--tds-spacing-element-32) !important;
  height: var(--tds-spacing-element-32) !important; 
  cursor: pointer;
  background-color: var(--tds-grey-200);
  border-radius: var(--tds-spacing-element-4);
  padding: var(--tds-spacing-element-4);  
}
