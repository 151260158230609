.search-mp-log-section {
  .title-container {
    svg {
      max-width: 20px;
      color: var(--sdds-blue-500);
      vertical-align: middle;
      margin-right: var(--sdds-spacing-layout-8);
    }
  }
  .input-row-1{
    display: flex;
    flex-wrap: wrap;
   
   .dd-column{
    min-width: 30%;
   }
    
    .type-code-column{
      min-width: 40%;
    }
  
  
  .dropdown-containers {
    margin-top: var(--sdds-spacing-layout-8);
    margin-right: var(--sdds-spacing-layout-16);
   
    input {
      height: 48px;
      border-bottom: 1px solid var(--sdds-textfield-border-bottom);
      
    }
  }
}

  .search-error {
    color: var(--sdds-negative);
  }

  .quick-search-container {
    display: flex;

    button {
      margin-right: var(--sdds-spacing-layout-16);
      margin-bottom: var(--sdds-spacing-layout-8);
    }
  }
}