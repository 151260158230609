.search-wrapper {
  background-color: var(--sdds-grey-50);
  border-radius: 5px;
  display: flex;
  margin-bottom: var(--sdds-spacing-element-16);

  .flex-display{
    display: flex;
    margin-right:var(--sdds-spacing-element-8);
    flex-wrap: wrap;
  
    .sdds-form-input[type=checkbox]+label{
      margin-right: var(--sdds-spacing-element-24);
      padding-top: 2px;
    }
  }

  .col{
    flex-direction: column;
  }

  time-info {
      svg {
        width: var(--tds-spacing-element-20);
        height: auto;
        margin-right: var(--sdds-spacing-layout-8);
  
        path {
          fill: var(--sdds-blue-500);
        }
      }
  }

  .flex {
    display: flex;
  }

  .space-between {
    justify-content: space-between;
  }

  .block {
    width: 100%;
  }

  .quick-options {
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;

    button {
      margin-right: var(--sdds-spacing-layout-16);
    }
  }

  .message-types-option {
    padding: var(--sdds-spacing-element-8);
    padding-top: var(--sdds-spacing-element-8);
  }

  .search-options {
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;
    justify-content: left;
    font-weight: bold;
    .react-datepicker-wrapper {
      min-width: 225px;
    }

    .react-datepicker {
      box-shadow: 2px 3px 2px 2px var(--sdds-grey-300);
      font-weight: normal;
      background-color: white !important;

      .react-datepicker__time-box {
        width: 84px;
      }

      .react-datepicker__time-list-item {
        background-color: white;
      }
    }

    .react-datepicker__input-container {
      input {
        width: 97%;
        padding-left: 0;
      }

      svg {
        right: 6px !important;
        top: 14px;
        left: unset !important;

        &:hover {
          cursor: pointer;
        }
      }
    }

  }

  .jr-ch-search {
    display: flex;
    gap: 12px; 
    height:20px; 
    .jy-ch-chart-in-section-tour{
      margin-top:-4px;
    }

  }
  
  
}
