.latency-indicators {
  display: flex;
  justify-content: flex-end;
  width:100%;
  margin-bottom:-40px;

  @media only screen and (max-width: 1400px) {
    margin-bottom:0px;
  }

  .txt {
    height: 20px;
    line-height: 20px;
    vertical-align: middle;
    display: inline-block;
  }

  .li {
    margin: 0px var(--sdds-spacing-element-8) var(--sdds-spacing-element-4) var(--sdds-spacing-element-8);

    svg {
      border-radius: 50%;
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      fill: #000;
    }

    &.low {
      svg {
        circle {
          fill: #1DAB8B;
        }

        margin-right: 4px;
      }
    }

    &.medium {
      svg {
        circle {
          fill: #F1C21B;
        }

        margin-right: 4px;
      }
    }

    &.high {
      svg {
        circle {
          fill: #FF2340;
        }

        margin-right: 4px;
      }
    }

    &.not-calculated {
      svg {
        circle {
          fill: #868FA2;
        }

        margin-right: 4px;
      }
    }
  }
}