.service-activation-container {
    width: 100%;
    margin: var(--sdds-spacing-layout-16) 0;

    table.sdds-table {
        width: 100%;

        .sdds-table__header .sdds-table__header-cell,
        td.sdds-table__body-cell.sdds-u-pr2.sdds-u-pl2 svg {
            vertical-align: middle;
        }

        .sdds-table__header .sdds-table__header-cell {
            height: auto;
        }
    }

    .sdds-table__body-cell {
        background: #F9FAFB;

        svg {
            fill: var(--sdds-blue-500);

            &:hover {
                +.custom-tooltip {
                    display: block;
                    position: absolute;
                    opacity: 1;
                }
            }
        }
    }

    .loader {
        width: 56px;
        margin: 0 auto;

        .spinner svg {
            max-width: 56px;
            min-height: 56px
        }
    }

    svg {
        max-width: 20px;
    }

    .latest-label {
        display: flex;
        flex-direction: column;
        span {
            svg {
                min-width: 20px;
            }
        }
    }

    .status-section {
        background: var(--tds-blue-100);
        padding: 12px;
      

        // font-size: 3rem;
        .section-title {
            display: flex;
            flex-wrap: wrap;

            /* Enable wrapping for multiple rows */
            .section-reason {
                flex: 1.2;
            }

            .section-info {
                flex: 2.8;
                display: flex;
                div{
                    margin-right: 32px;
                }
            }

            div {
                text-align: left;

                svg {
                    min-width: 20px;
                }
            }
        }

        .label-color {
            background: #2B70D3;
            padding: 4px 16px;
            border-radius: 20px;
            color: white;
            font-size: 12px;
        }

    }


}

.service-configuration-page .service-configuration-container .dt-container.aggregate-container {
    .sar-title-section{
        display: flex;
        align-items: center;
        .time-info{
            flex:1;
            display: flex;
           justify-content: flex-end;
        }
    }
    
    tds-folder-tabs {
        button {
            font-size: 3rem;
        }

    }

    .aggr-status-line {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .aggr-status {           
            display: flex;
            flex: 1;
            align-items: center;
            min-width:600px;
            
        }

        .aggr-status-actions {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }
    }

}

.latest .latest-table {
    padding: 2rem 4rem;
    width: 100%;
}

.handle-right {
    text-align: end;
    width: 100%;
    margin: 8px 20px;

    sdds-tooltip {
        display: none;
    }
}

.service-activation-container table.sdds-table .sdds-table__header .sdds-table__header-cell {
    height: auto;
}

.section-box {
    background: #e9eef6c2;
    padding-bottom: 8px;
}