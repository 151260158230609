.cd-table {
    width: 100%
}

.table-wrapper .timeSvg svg {
  width:16px;
  height: auto;
  margin-right: var(--sdds-spacing-layout-8);
  vertical-align: text-top;
}
.ch-tooltip .tds-tooltip.sc-tds-tooltip {
  max-width: none !important;
}
