.rtc-log-page {
  position: relative;

  .spin-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-blend-mode: hard-light;
    z-index: 1;
    opacity: 1;

    .spinner {
      margin: 25% auto;
    }
  }

  .rtc-log-container {
    .dt-container {
      margin-top: var(--sdds-spacing-layout-32);
      min-height: calc(100vh - 326px);
      background-color: var(--sdds-grey-50);
      border: 1px solid var(--sdds-grey-400);
      border-radius: 5px;
      overflow-y: auto;
      position: relative;

      .cell {
        vertical-align: middle;
      }
    }
    .flex-row{
      display: flex;
      justify-content: flex-end;
      button{
        margin-left: var(--sdds-spacing-element-24);
        margin-top: var(--sdds-spacing-element-8);
      }
    }
  }
}

.sdds-toast {
  position: fixed;
  top: var(--sdds-spacing-layout-72);
  right: 8px;
  margin-bottom: var(--sdds-spacing-layout-16);
  animation: toast-in-right 0.4s;
}

#confirm-ssh,
#rtc-log-contents,
#confirm-rtc-Logs {
  padding-bottom: var(--sdds-spacing-layout-16);

  .sdds-modal-body {
    &.modalBodyOveride {
      min-height: auto !important;
      margin-bottom: var(--sdds-spacing-layout-8);
    }
  }
}

.even{
  min-height: 26px;
  background-color: var(--sdds-white);
  padding-bottom: 0;
  &:hover{
    background-color: var(--sdds-grey-200);
  }
}
.odd{
  min-height: 26px;
  background-color: var(--sdds-grey-50);
  padding-bottom: 0;
  &:hover{
    background-color: var(--sdds-grey-200);
  }
}