.service-configuration-page {
  display: flex;

  .service-configuration-container {
    .ta-row {
      background-color: var(--sdds-grey-50);
     // padding-bottom: var(--sdds-spacing-layout-16);
      margin-bottom: var(--sdds-spacing-layout-16);
    }

    .dt-row {
      .dt-col {
        .dt-container {
          min-height: 480px;
          background-color: var(--sdds-grey-50);
          border: 1px solid var(--sdds-grey-400);
          border-radius: 5px;
          overflow-x: auto;
          padding-bottom: var(--sdds-spacing-layout-32);
          position: relative;
          .btn-export {
            svg {
              width: 16px;
              height: 16px;
            }

            .sdds-btn-icon {
              display: inline-block;
            }
          }

          table {
            width: 100%;
          }
          .spin-container {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: transparent;
            background-blend-mode: hard-light;
            z-index: 1;
            opacity: 1;
        
            .spinner {
              margin:20% 10%;
            }
          }
        }
      }
    }
    svg {
      color: #2B70D3;
      vertical-align: middle;
      margin-right: 3px;
    }
    span.cd-table__searchbar-icon svg {
      color: var(--sdds-grey-958)
    }
    span.sdds-btn-icon svg {
      color: var(--sdds-btn-primary-color);
    }
    .dt-container.aggregate-container {
      padding: 0 3rem;
      strong {
        line-height: 40px;
        margin-right: 6px;
        font-size: 16px;;
      }
      .service-activation-container strong {
        line-height: normal;
      }
      button.refresh-btn {
        padding: 8px;
      }
      button.refresh-btn:hover {
        background-color: var(--sdds-grey-100);
        border-color: var(--sdds-grey-50);
      }

      button {
        margin-right: 3rem;
        padding: 3rem 8rem;
        height: auto;
      }
      .status-indicator {
        padding:4px;
        margin-left:4px;
        border-radius: 1rem;
        
      }
      .status-indicator.success {
          background-color: #1dab8b;
          color: white;
        }
      .status-indicator.pending {
          background-color: #f1c21b;
        }
      .status-indicator.failed {
          background-color: #ff2340;
          color: white;
        }
      
    }

  }
  
}

@media (min-width: 1584px) {
  .sdds-container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

.sdds-table .sdds-table__upper-bar-flex {
  align-items: center
}
.panels {
  height: 94%;
}


.service-configuration-page .sdds-table .sdds-btn {
  margin-left: 12px
}
.value-dropdown{
  margin-left: var(--sdds-spacing-layout-8);
  display: block;
  width: 205px;
}
.text-positive{
  color:var(--sdds-positive);
}
.text-negative{
  color:var(--sdds-negative);
}

.sdds-slider-wrapper {
  padding: 40px 0 0;
}