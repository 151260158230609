.sa-handle{
    display: flex;
         .handle-copy{
             visibility: hidden;
             width:24px;
             height:20px;
             margin-left: 4px;
         }
     }
 
 
 .sa-handle:hover{
 background-color:var(--tds-grey-300) ;
     .handle-copy{
         visibility: visible;
        
     }
     .handle-copy:hover{
        cursor: pointer;
        
     }
 }
 
 .csc-failed{
     color: var(--tds-negative);
 }
 
 .jy-sc-status-section {
  margin-left: var(--tds-spacing-element-12); 
  display: inline-flex;
  align-items: center;
  margin-top:-4px;
 }
 .jy-sc-activation-table-section{
    display: inline-flex;
    align-items: center;
 }