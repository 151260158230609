.comm-history-page {
  box-sizing: border-box;
  .tab-container{
      background-color: var(--sdds-grey-50);
      padding: 24px 24px 0px;

      .viewdata {
        display: flex;
        width: 100%;
        .align-left{
          flex:1;
          display: flex;
          flex-direction: column;          
        }
        
        .align-right{
          flex:1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

  }
.table-wrapper {
  overflow-x: auto !important;
}
}

.message-filters {
  display: flex;
  flex-direction: row;
  padding: 16px 0px 16px 0px;
  button {
    margin-right: var(--sdds-spacing-element-8);
    margin-left: var(--sdds-spacing-element-8);
    vertical-align: middle;
    display: flex;
  }

  #multi-select-message {
    vertical-align: middle;
    display: inline-block;
    min-width: 250px;
    margin-left: var(--sdds-spacing-element-16);
  }

  .checkbox {
    align-content: center;

    .sdds-form-input[type=checkbox]+label {
      margin-right: var(--sdds-spacing-element-24);
      padding-top: 2px;
    }
  }
}

.message-filters>* {
  align-self: center;
}

.flex-display {
  display: flex;
  margin-right: var(--sdds-spacing-element-8);
  flex-wrap: wrap;
}

.padding-top {
  padding-top: 20px
}

.marker {
  width: var(--sdds-spacing-element-12);
  height: var(--sdds-spacing-element-12);
  display: inline-block;
  margin-right: var(--sdds-spacing-element-4);
  margin-top: var(--sdds-spacing-element-4);

  &.current-status {
    background-color: var(--sdds-blue-600);
  }

  &.position {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid var(--sdds-blue-300);

  }

  &.function-indication {
    background-color: var(--sdds-blue-200);
    border-radius: 50%;
  }
}

.table-wrapper .timeSvg svg {
  width: 14px;
  height: auto;
  margin-right: var(--sdds-spacing-layout-8);
  vertical-align: text-top;
}

.sdds-table,
.table-wrapper {
  width: 100%
}


.table-wrapper::-webkit-scrollbar-thumb {
  border: 5px solid var(--sdds-white) !important;
}


.sdds-table .sdds-table__header .sdds-table__header-cell,
.sdds-table .sdds-table__body-cell {
  min-width: inherit !important;
}

.sdds-table__header-cell,
.sdds-table__body-cell {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding:8px !important;
}
.sdds-table__body-cell.undefined {
  overflow: inherit !important;
}
.map-container {
  display: flex;
  flex-direction: column;


  .map-control {
    min-height: 64vh;
    min-width: 63vw;
    height: 500px;
    overflow: hidden;
    z-index: 149;
  }

  .here-map-tooltip .H_ib_body {
    width: 20em;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_el {
    height: 40px;
  }

  .H_menu_title {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    background-color: #575B63;
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;
    cursor: default;
  }

  .H_btn_no_shadow {
    box-shadow: none !important;
  }

  .H_btn.H_active {
    background-color: inherit !important;
    font-weight: bold;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_el {
    height: 40px;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_btn span {
    margin-left: 15px;
    font-size: 10px;
    line-height: 10px;
    display: block;
    font-weight: normal;
  }

  .H_center {
    // display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 100%;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_btn:before {
    content: '\2713\0020';
    font-weight: bold;
  }

  .H_rdo.H_rdo_custom_text_labels .H_rdo_buttons .H_btn:not(.H_active):before {
    color: #fff;
  }

  .H_menu_title.H_menu_title_top {
    border-radius: 5px 5px 0 0;
  }


  .H_ctl {
    .H_btn {
      margin-bottom: var(--sdds-spacing-layout-8);

      img {
        width: 40px;
        height: 40px
      }

      span {
        display: block;

        img {
          display: inline-block;
          vertical-align: middle;
          padding-right: var(--sdds-spacing-layout-8);
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .H_ctl .H_overlay .H_btn {
    margin-bottom: 0
  }
}

.sdds-inline-tabs-wrapper {
  width: 450px;
}

.tabs-container {
  position: relative;

  .latency-indicators {
    position: absolute;
    top: -2px;
    right: 0;
    z-index: 99;
  }
}

/* .sticky {
  background-color: var(--sdds-white);
  position: sticky;
  align-items: center;
  z-index: 2;
  top: -16px;
  transition: position 2000ms ease-in;
  width: 100%;
  opacity: 1;
} */

.metadata-marginTop {
  margin-top: 52px;
}

.help-section{
  display: flex;
  width:100%;
  justify-content: flex-end;
  margin-bottom:8px;
  
  button{
    margin-right: 24px;
  }
}

.ch-table-in-section{
  display: flex;
  justify-content: center;
  align-items: center;
}
