.search-box {
  background-color: var(--tds-grey-50);
  .helper-text{    
    padding: var(--tds-spacing-layout-16) 0 0 var(--tds-spacing-layout-16);
    .desc-text{
      font-weight: normal;
    }
  }
  
  .container {
    display: flex;
    flex-direction: row;
    margin: var(--tds-spacing-layout-8) 0;
    justify-content: flex-start;
   
    .radio-button-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      tds-radio-button {
        padding: var(--tds-spacing-layout-16);
      }
    }

    .item {
      padding: var(--tds-spacing-layout-16);
      padding-left: 0;
      border: none;
    }

    .itemInput {
      line-height: normal;
      height: 56px;
      margin: 16px 20px 0 0;
      min-width: 572px;
      padding: 10px
    }
  }
}