.landing-page {
  display: flex;
  height: 100%;
  flex-direction: row;

  .overview-collapsed + .st-view-wrapper {
    width: calc(100vw - 13px);
  }

  .st-view-wrapper {
    width: calc(100% - 500px);
    .panels {
      padding: 0;
      width: 100%;
      display: flex;
      overflow: hidden;
      flex-direction: row;
      height: 100%;
      .panel {
        padding: var(--sdds-spacing-layout-16);
        padding-right: 0;
        flex: 1;
      }
      .active {
        height: calc(100vh - 120px) !important;
        overflow-y: auto !important;   
        scroll-behavior: smooth;
      }
    }
    .sdds-inline-tabs-fullbleed--tab {
      cursor: pointer;
      user-select: none;
      position: relative;
      z-index: 110;
      .tooltipLockIcon {
        position: relative;
        svg {
          height: 16px;
          vertical-align: bottom;
        }
        .hydrated {
          position: fixed;
          z-index: 999;
        }
      }
    }
  }

  .st-view-container {
    display: flex;
    flex-direction: column;
    margin: 0 var(--sdds-spacing-layout-8);
  }

  .section-scrollable {
    overflow-y: scroll;
    height: calc(100vh - 220px);
  }

  .viewdata {
    .right {
      text-align: right;
    }

    svg {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      color: #2b70d3;
    }
  }

  .sdds-table .sdds-table__searchbar .sdds-table__searchbar-icon {
    z-index: 1;
  }

  .sdds-btn-icon svg {
    width: 16px;
    height: 16px;
  }
  .no-data-content {
    display: inline-flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      margin-right: var(--sdds-spacing-element-4);
      fill: var(--tds-banner-prefix-info-color);
      color: var(--tds-banner-prefix-info-color);
    }
  }
}
