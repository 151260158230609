.batch-search-page {
  padding-top: var(--sdds-spacing-layout-32);
  display: flex;
  height: calc(100vh - 64px);
  overflow-y: auto;

  .batchSearchNote{
    color: var(--sdds-grey-700) !important;
  }

  .batch-search-container {
    .ta-row {
      background-color: var(--sdds-grey-50);
      padding-top: var(--sdds-spacing-layout-32);
      padding-bottom: var(--sdds-spacing-layout-16);
      margin-bottom: var(--sdds-spacing-layout-32);

      .btn-col {
        @media only screen and (max-width: 600px) {
          margin-bottom: var(--sdds-spacing-layout-32);

          .btn-wrapper {
            display: flex;
            flex-direction: row;
            height: auto;
            width: 100%;
            padding: var(--sdds-spacing-layout-8);
          }
        }
      }

      .flex {
        display: flex;
        height: 100%;

        .btn-wrapper {
          display: flex;
          flex-flow: column wrap;
          align-self: flex-end;
          height: 228px;

          .sdds-btn-primary {
            margin-bottom: var(--sdds-spacing-layout-16);
          }
        }

        .not-found-wrapper {
          display: flex;
          flex: 0 0 100%;
          align-items: flex-start;
          flex-direction: column-reverse;
          height: 100%;

          .sdds-headline-07 {
            display: flex;
            align-self: flex-start;
          }

          .not-found-box {
            display: flex;
            height: 226px;
            width: 100%;
            flex-basis: 1;
            background-color: var(--sdds-grey-50);
            border: 1px solid var(--sdds-negative);
            overflow-y: auto;
            padding: var(--sdds-spacing-layout-16);
            flex-direction: column;

            div {
              display: block;
            }
          }
        }
      }

    }

    textarea {
      width: 100%;
      height: 224px;
      padding: var(--sdds-spacing-layout-16);
      resize: none;
    }

    .dt-row {
      .dt-col {
        .dt-container {
          min-height: 480px;
          background-color: var(--sdds-grey-50);
          border: 1px solid var(--sdds-grey-400);
          border-radius: 5px;
          overflow-x: auto;
          padding-bottom: var(--sdds-spacing-layout-32);

          .btn-export {
            svg {
              width: 16px;
              height: 16px;
            }

            .sdds-btn-icon {
              display: inline-block;
            }
          }

          table {
            width: 100%;
          }

          .loader {
            border-radius: 5px;
            background: var(--sdds-white);
            opacity: 0.8;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99;
            .spinner {
              position: relative;
              z-index: 100;
              top: 50%; 
              flex-direction: column;
              color: var(--sdds-grey-958);
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              .spinner {
                padding-top: var(--sdds-spacing-layout-16);
                .hydrated {
                  display: flex;
                  flex-direction: column-reverse;
                }
                .percentage {
                    padding-top: 10px;
                }
              }
            }
          }

          .sdds-table__row {
            .last-line {
              display: flex;
              flex-direction: column;
              margin-top: var(--sdds-spacing-element-8);

              .redirect-labels {
                display: flex;
                width: fit-content;
                margin-top: var(--sdds-spacing-element-8);
                cursor: pointer;
                
                .redirect {
                  display: flex;
                }

                .redirect-icon {
                  width: var(--sdds-spacing-element-16);
                  height: var(--sdds-spacing-element-16);
                  color: var(--sdds-blue-500);
                  margin-right: var(--sdds-spacing-element-4);
                }

                .redirect-link {
                  color: var(--sdds-blue-500);
                  &:visited {
                    color: var(--sdds-blue-500);
                    text-decoration: none;
                  }
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }

            .hide {
              visibility: hidden;
            }
            &:hover {
              .hide {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  
  .discontinuation-msg-container {
    display: flex;
    height: var(--sdds-spacing-layout-56);
    width: 100%;
    background-color: var(--sdds-warning);
    padding: var(--sdds-spacing-element-8) var(--sdds-spacing-element-16);
  
    svg {
      height: var(--sdds-spacing-element-32);
      width: var(--sdds-spacing-element-32);
      fill: var(--sdds-blue-500);
    }
  
    .msg-section {
      display: flex;
      flex: 9;
      flex-direction: column;
      margin-left: var(--sdds-spacing-element-8);
    }
  
    .close-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  
      svg {
        height: var(--sdds-spacing-element-16);
        width: var(--sdds-spacing-element-16);
        cursor: pointer;
      }
    }
  }
}