.controls button {
    margin-right: var(--sdds-spacing-element-16);
}

#historical-data-modal {
    &.customModal {
        padding: 16px;
        max-height: inherit !important;
        overflow-y: inherit !important;

        .sdds-badges-demo {
            margin: 20px;
            height: 50px;
            width: 50px;
            position: relative;
            background-color: #C4C4C4;
        }

        .sdds-modal-body>* {
            padding-bottom: 2px;
        }

        .sdds-badges-demo sdds-badges {
            position: absolute;
            left: 32px;
            top: -5px;
            --sdds-badges-bg-color: var(--sdds-green-500);
        }

        .sdds-modal-actions button {
            margin-top: var(--sdds-spacing-element-16);
            ;
        }

        .sdds-textfield-input {
            min-width: 208px;
            max-width: 208px;
            height: 50px;
            float: right;
            position: relative;
            top: -16px;
            border: 1px solid var(--sdds-grey-600);

            &:hover {
                border-bottom-color: var(--sdds-textfield-border-bottom-hover);
            }

            @media only screen and (max-width: 768px) {
                float: none;
                top: 0;
            }
        }

        ::placeholder {
            color: var(--sdds-grey-600);
            opacity: 1;
        }

        ::-ms-input-placeholder {
            /* Edge 12-18 */
            color: var(--sdds-grey-600);
        }
    }
}

.container {
    width: 100%;
    font-size: 12px;

    .list-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1px;
        margin: 0 0 10px;
        padding: 0;
        list-style: none;
        width: 100%;
        min-width: 0;

        li {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 20px;
            text-transform: capitalize;
            display: inline-block;
            width: calc(30%);

            @media only screen and (max-width: 920px) {
                width: calc(49%);
            }

            @media only screen and (max-width: 480px) {
                width: 100%;
            }
        }

        .sdds-form-input[type=checkbox] {
            vertical-align: bottom;
        }
    }
}