.vehicle-rtc-page {
  padding: var(--tds-spacing-layout-16);
  display: flex;
  height: calc(100vh - 64px);
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  .search-row {
    background-color: var(--tds-grey-50);
    padding: var(--tds-spacing-layout-16);
    margin: var(--tds-spacing-layout-16) 0;

    .flex {
      display: flex;
      height: 100%;
    }
  }

  .details-row {
    .utc-info {
      display: flex;
      margin-top:var(--tds-spacing-element-32);
      svg {
        width: 20px;
        height: 20px;
        margin-right: var(--tds-spacing-layout-8);
        fill: var(--tds-banner-prefix-info-color);
        color: var(--tds-banner-prefix-info-color);
      }
    }

    .showInfo {
      margin: 10% 0;
      text-align: center;

      svg {
        width: 30px;
        height: 30px;
        margin-right: var(--tds-spacing-layout-8);
        fill: var(--tds-banner-prefix-info-color);
        color: var(--tds-banner-prefix-info-color);
      }
      span {
        text-align: center;
        display: inline-block;
        span {
          display: block;
          text-align: left;
        }
      }
      span.noData {
        vertical-align: 9px;
      }
    }
    .details {
      background: #edeff3;
      padding: var(--tds-spacing-layout-16);
      margin: var(--tds-spacing-layout-8) 0;
      h2 {
        display: flex;
        font-size: 16px;
        font-size: 16px;
        line-height: normal;
        svg {
          width: 20px;
          height: 20px;
          margin-right: var(--tds-spacing-layout-8);
          fill: var(--tds-banner-prefix-info-color);
          color: var(--tds-banner-prefix-info-color);
        }
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        li {
          flex: auto;
          justify-content: flex-start;
          span {
            display: block;
            font-weight: bold;
            padding-bottom: 10px;
          }
        }
      }
    }

    .history {
      margin: var(--tds-spacing-element-16) 0;
      h2 {
        background-color: var(--sdds-grey-400);
        color: var(--sdds-grey-958);
        padding: var(--sdds-spacing-element-16);
        width: 100%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin: 0;
        display: flex;
        font-size: 16px;
        font-size: 16px;
        line-height: normal;
        svg {
          width: 20px;
          height: 20px;
          margin-right: var(--tds-spacing-layout-8);
          fill: var(--tds-banner-prefix-info-color);
          color: var(--tds-banner-prefix-info-color);
        }
      }
      td.sdds-table__body-cell.freeze {
        max-width: 192px;
        vertical-align: middle;
        a {
          text-decoration: none;
          color: var(--tds-blue-500);
          line-height: normal;
          &:hover {
            color: var(--tds-blue-300);
          }
        }
      }
      .sdds-table__row .redirect {
        height: var(--tds-spacing-element-20);
        width: var(--tds-spacing-element-20);
        margin-bottom: -4px;
        margin-right: var(--tds-spacing-element-4);
      }
      .cd-table {
        display: none;
      }
      .table-wrapper table.sdds-table tr span.button {
        visibility: hidden;
      }
      .table-wrapper table.sdds-table tr:hover span.button,
      span.button {
        visibility: visible;
      }
    }
    .history.rtc-table .cd-table {
      display: block;
      span:first-child {
        background-image: url("../../components/assets/Rtcsvg.svg");
        background-repeat: no-repeat;
        background-position: left center;
        color: var(--sdds-grey-958);
        padding: var(--sdds-spacing-element-16) var(--sdds-spacing-element-16)
          var(--sdds-spacing-element-16) var(--sdds-spacing-element-32);
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
}
